@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');

//--------Bootstrap overrides --------
//Custom variable values must be placed before default bootstrap .scss

//Use '~bootstrap/scss/_variables.scss' to know which variables to change

$primary: rgb(39, 39, 39);
$secondary: rgb(160, 160, 160);
$light: transparent;
$dropdown-link-active-color: transparent;
$dropdown-spacer: 0px;
$dropdown-min-width: fit-content;
$btn-focus-box-shadow: none;
$enable-caret: false;

// if wanted all:
//@import '~bootstrap/scss/bootstrap.scss';

// Required
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

// Bootstrap components to import
//use navbar, utilities, transitions later
@import 'node_modules/bootstrap/scss/dropdown';
@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/button-group';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/input-group';
@import 'node_modules/bootstrap/scss/popover';
@import 'node_modules/bootstrap/scss/navbar';
@import 'node_modules/bootstrap/scss/nav';
@import 'node_modules/bootstrap/scss/grid';

//Specific custom styles must be placed after the import

//Custom button variant: 'transparent'
.btn-transparent {
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 2px;
  display: flex;
  border-color: transparent;
  background-color: transparent;
  width: max-content;
  height: fit-content;
  border-radius: 2px;
  box-shadow: none;
  &:hover {
    background-color: rgba(233, 230, 237, 1);
    border-color: transparent;
  }
  &:active {
    color: inherit;
    background-color: #e9e6e3;
  }
  &:focus {
    outline: none;
    background-color: rgba(233, 230, 237, 1);
    box-shadow: none;
  }
}

.btn-group-xs > .btn, .btn-xs {
  padding  : .5rem .4rem;
  font-size  : .75rem;
  line-height  : .5;
  border-radius : .2rem;
}

.btn-success {
  background-color: transparent;
  border-color: transparent;
  &:hover {
    background-color: rgba(233, 230, 237, 1);
    border-color: transparent;
  }
  &:focus {
    outline: none;
    background-color: rgba(233, 230, 237, 1);
  }
}

.dropdown {
  a {
    color: inherit;
    text-decoration: none;
  }
}

.dropdown-item {
  font-size: 14px;
  width: auto;
  padding: 0.01rem .75rem;

  &:active {
    color: inherit;
    background-color: #e9e6e3;
  }
  &:focus {
    outline: none;
    background-color: rgba(233, 230, 237, 1);
  }
}

.dropdown-menu.show {
  display: flex;
  flex-direction: column;
}

.form-control {
  width: 95%;
}

.sortableHelper {
  background: #f7f7f7;
  box-shadow: -2px 4px 12px 1px rgba(0, 0, 0, 0.125);
  opacity: 1;
}
